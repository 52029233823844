* {
  margin: 0;
  padding: 0;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
  background: #ffff;
}
.main-login__section {
  overflow: hidden;
  min-height: 100vh;
}
.sidebar-icon {
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
}
.option-values {
  color: transparent;
}

.each__section .responsive__table {
  width: 100%;
  padding: 0px 16px;
}
.tooltip__question {
  border-radius: 5px;
  background: rgb(239, 239, 239);
  border: 1px solid rgb(223, 222, 222);
  padding: 5px 10px 5px 10px;
  margin-top: 3px;
  margin-bottom: 4px;
}
.tooltip-corn {
  /* width: 25px;
  height: 20px;
  display: flex;
  justify-content: end;
  margin-top: -5px; 
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  background: rgb(239, 239, 239); */
  display: flex;
  justify-content: end;
  margin-top: -5px;
  margin-right: 4px;
}
.tooltip-corn img {
  width: 20px;
  height: 20px;
}
.options__fill {
  background: rgb(187, 237, 255);
  color: #000;
  border: 1px solid rgb(61, 110, 171);
  padding: 10px;
  text-align: center;
  margin-top: 10px;
  border-radius: 10px;
}
.secondary-slider-pics {
  height: 40px !important;
}
.update-cat-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  background: #000d50;
  color: #fff;
  padding: 12px;

  width: 100%;
  border: none;
  border-radius: 10px;
}
.tooltip__question h5 {
  font-size: 14px;
}
.action-cat {
  display: flex;
  align-items: center;
  gap: 8px;
}
.action-cat img {
  cursor: pointer;
}
.tooltip__question .sub {
  font-size: 12px !important ;
  font-weight: lighter !important;
}
.each__section .responsive__table::-webkit-scrollbar {
  display: none;
  scrollbar-width: auto;
  height: 5px;
}

/* Track */
.each__section .responsive__table::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

/* Handle */
.each__section .responsive__table::-webkit-scrollbar-thumb {
  background: #3b3b3b;
  border-radius: 10px;
}

/* Handle on hover */
.each__section .responsive__table::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
  opacity: 0;
}

.secondarypictures {
  display: flex;
  gap: 5px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.secondary__pics__img {
  width: 80px;
  height: 50px;
}
.screen-img {
  position: relative;
  overflow: hidden;
  padding-bottom: 65px !important;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  background: #fff;

  /* background: linear-gradient(#2C3333 , #3D3C42) ; */
  padding: 10px;
  border-radius: 12px;
  margin: 0px;
  width: 265px !important;
}
.home-banner {
  position: relative;
  overflow: hidden;
  padding-bottom: 65px !important;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  background: #fff;

  /* background: linear-gradient(#2C3333 , #3D3C42) ; */
  padding: 10px;
  border-radius: 12px;
  margin: 0px;
  width: 300px !important;
  /* height: 310px; */
}

.no_page_found {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}
.no_page_found h1 {
  font-size: 100px;
  font-weight: 500;
  text-align: center;
}
.no_page_found h5 {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
.no_page_found p {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.no_page_found button {
  display: flex;
  margin: auto;
  border: none;
  padding: 10px 30px;
  font-size: 20px;
  margin-top: 15px;
  font-weight: bold;
  color: #fff;
  border-radius: 10px;
  background: rgba(242, 153, 74, 1);
}

.no_page_found img {
  padding-top: 50px;
}
.bannerImg img {
  /* width: 268px;
  height: 180px; */
  width: 100%;
  height: 205px;
  background: transparent;
  border: 0.5px solid #578bb6;
  border-radius: 12px;
}
.slick-dots {
  bottom: -15px !important;
}

.update-img-btn {
  position: absolute;
  bottom: 5px;
  left: 10px;
  right: 10px;
  width: auto !important;
}

.update-img {
  margin-right: 11px;
}

.home-banner h5,
.screen-img h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 30px;
  color: #000000;
  padding: 5px;
}

.home-banner button,
.screen-img button {
  border: 1px solid #fff;
  color: #fff;
  padding: 10px;
  background: #000d50;
  border-radius: 8px;
  width: 100%;
}

.survey-banner {
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  background: #fff;

  /* background: linear-gradient(#2C3333 , #3D3C42) ; */
  padding: 10px;
  border-radius: 12px;
  margin: 0px;
  width: 300px !important;
  /* height: 310px; */
}
.slick-slide .bannerImg {
  width: 100% !important;
  padding: 10px !important;
  border-radius: 20px !important;
  height: 205px !important;
  border: 1px solid #578bb6 !important;
  box-shadow: none;
  margin: 0;
}
.slick-slide .secondary-slider-pics {
  width: 100% !important;

  border-radius: 10px !important;
  height: 120px !important;
}

.survey-banner .banner-survey {
  width: 100% !important;
  height: 205px;
  border-radius: 20px !important;
}
.home-banner button,
.screen-img button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.survey-banner h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 30px;
  color: #000000;
  padding: 5px;
}

.bannerImg {
  margin-bottom: 16px;
}

.survey-banner button {
  border: 1px solid #fff;
  color: #fff;
  padding: 10px;
  background: #000d50;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image__col {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(rgb(248, 246, 246) , #fff); */
  padding: 50px 55px 55px 55px !important;
  min-height: 100vh;
}

.image__col .img-box img {
  width: 80%;
  height: auto;
  display: block;
}

.update__home__screen__banner button {
  border: 1px solid #fff;
  color: #fff;
  padding: 10px;
  margin-top: 15px;
  background: linear-gradient(#293462, #06283d);

  border-radius: 10px;
  width: 100%;
}

.image__col .survey h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  padding: 25px;
  color: #ffffff;
  text-align: center;
  letter-spacing: -0.02em;
  margin: 0;
}

.image__col .survey p {
  text-align: center;
  padding: 15px 51px 15px 51px;
  font-size: 22px;
  margin: 0;
  color: #fff;
}

.image__col {
  background: #000d50;
}

/* .image__col img:hover{
    transform: scale(1.02);
    transition: 0.5s;
  } */
.image__col .img-box {
  margin: 15px auto 0;
  display: flex;
  justify-content: center;
}

.second-img p {
  margin-bottom: 0px !important;
  margin-left: 15px;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins";
}

.second-img {
  align-items: center;
}

.first-td {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.main-login {
  height: 100vh;
  /* overflow: scroll; */
}

.login__col {
  padding: 50px 100px !important;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.login-head h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  padding-bottom: 30px;
  letter-spacing: -0.02em;
  color: #000d50;
}

.error__msg {
  color: red;
  margin-top: 5px;
}

.login-head p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: #000d50;
  padding-bottom: 69px;
  margin: 0;
}

.login__col h2 {
  font-weight: bold;
  text-align: center;
  letter-spacing: 2px;
}

.login__col label {
  width: 100%;
  margin-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #1d2939;
}
.update-quest {
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  padding: 11px;
  border: none;
  color: #f5fbff;
  background: #000d50;
  border-radius: 10px;
}
.image-url-eclips {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.login__col label input {
  margin-top: 0px;
  border: none;
  padding: 0px 0px 0px 42px;
  width: 100%;
  height: 60px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  border: 1px solid #98a2b3;
  border-radius: 10px;
  color: #000 !important;
}

.login__col label input::placeholder {
  color: #98a2b3 !important;
}

/* .login__col label input:hover{
    background: rgb(248, 248, 248);
    cursor: pointer;
  } */

.feild-icons {
  position: relative;
  margin-top: 10px;
}

.icons-feild img {
  position: absolute;
  top: 16px;
  left: 11px;
  color: #98a2b3;
}

.icons-feild-2 img {
  position: absolute;
  top: 23px;
  width: 22.5px;
  height: 17.5px;
  color: #98a2b3;
  left: 11px;
}

.icons-feild-1 {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}

/* .feild-icons-1 .ri-lock-fill{position: absolute;
      top: 18px;
      left: 8px;} */
/* .feild-icons-1 {position: relative} */
.login__col input:focus {
  outline: none;
  border: 1.4px solid rgb(11, 16, 5);
}

/* .email input{position: relative;}
  .email input:before{
    position: absolute;
    content: "";
  
  } */
.login__col button {
  background: linear-gradient(#150432, #1f1b43);
  width: 100%;
  height: 60px;
  margin-top: 35px;
  padding: 10px 15px;
  color: #fcfcfd;
  background: #000d50;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}
.update_question {
  background-color: #000d50;
  color: #f5fbff;
  border: none;
  border-radius: 8px;
  padding: 10px;
}
.update_ImgRef {
  display: none;
}

.update-option:focus {
  border: none;
  outline: none;
}
.cameraIcon {
  position: absolute;
  margin-top: 15px;
  margin-left: 10px;

  cursor: pointer;
}
.cameraIcon img {
  width: 25px;
  height: 25px;
}
.update_imgRef {
  width: 100%;
  height: 250px;
  margin-top: 10px;
}
.cancel_update_question {
  background-color: #ffffff;
  border: 1px solid #000d50;
  border-radius: 8px;
  color: #000d50;
  text-align: center;
  cursor: pointer;
  padding: 10px 16px 10px 16px;
}
.modal-question {
  background: #fff !important;
  /* padding: 15px !important; */
}
.main__section {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

.home-banner,
.screen-img {
  margin-right: 23px;
}

.banner__images {
  margin: 42px 32px 42px 32px !important;
}

.sidebar__section {
  width: 80px;
  background: linear-gradient(#080808, #151515);
  color: #fff;
  position: fixed;
  height: 100vh;
  transition: 0.9s;
  box-shadow: 5px 5px 15px -5px #dfe4e4;
  border-right: 2px solid rgb(255, 255, 255);
}
.sec-pics {
  width: 100%;
  margin-bottom: 10px;
  height: 200px;
  border: 1px solid rgb(87, 139, 182);
  padding: 10px;
  border-radius: 20px;
}
.bricks-data {
  margin-bottom: 0 !important;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  background: #dbe8ff;
  box-shadow: 5px 5px 15px -5px #dcd0ff;
  border: 1px solid #dbd5d5;
  margin-top: 5px !important;
}
.charity-images-head {
  font-size: 17px !important;
}
.single-vharity-view-img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 5px 15px -5px #dfe4e4;
}
.view-image-position {
  position: absolute;
  top: 10px;
  left: 15px;
}
.view-image-position h4 {
  background: rgba(5, 73, 112, 1);
  padding: 5px 10px;
  font-size: 18px !important;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 10px;
  color: #fff !important;
}
.each__section {
  width: calc(100% - 300px);
  margin-left: 300px;
  color: #000;
  /* padding: 10px; */
}

/* .modal {
  display: flex !important;
  align-items: center !important;
} */
/* Meida Query */
@media only screen and (max-width: 1090px) {
  .form-sec-mac {
    padding: 20px;
  }
  .add-category button {
    font-weight: 500;
    font-size: 14px;
    padding: 15px 10px;
    line-height: 21px;
  }
  .add__charity button {
    font-weight: 500;
    font-size: 14px;
    padding: 13px 10px;
    line-height: 25px;
  }
  .login__col {
    padding: 0px 50px !important;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}
::-webkit-scrollbar-track {
  background: rgba(74, 75, 77, 0.1);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.count__section p {
  min-height: 48px;
  margin-bottom: 0;
}
.single-img img {
  height: 250px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid rgba(5, 73, 112, 1);
}
.category-all {
  width: 100%;
  padding: 10px;
  background: #fff;
  text-align: start;
  border-radius: 10px;
  box-shadow: 5px 5px 14px -5px #dfe4e4;
  border: none;
}

.sidebar__section__open {
  width: 300px;
  height: 100%;
  background: #000d50;
  color: #fff;
  position: fixed;
  transition: 0.5s;

  box-shadow: 5px 5px 15px -5px #dfe4e4;
  border-right: 2px solid rgb(255, 255, 255);
}

.sidebar__section a {
  text-decoration: none;
  color: #fff;
}

.sidebar__section a:hover {
  color: #fff;
}

.each__section {
  width: calc(100% - 300px);
  margin-left: 300px;
  color: #000;
  /* padding: 10px; */
}

.count__section {
  padding: 33px;
  margin-top: 32px !important;
  background: #f5fbff;
  border-radius: 12.0899px;
}

.main-cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 500px));
  grid-gap: 32px;
  justify-content: space-around;
  padding: 0px 32px !important;
}

.field__compulsory span {
  color: red;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

/* .imgsecondarypics {
   width: 100% !important;
   height: 170px !important;
   background-size: cover;
   background-repeat: no-repeat;
} */
.slick-slide img {
  display: block;
  box-shadow: 0px 12px 16px -4px rgb(16, 24, 40, 8%),
    0px 4px 6px -2px rgb(16, 24, 40, 3%);
  border-radius: 12px;
  width: 300px;
  height: 200px;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #000d50 !important;
  background-color: transparent !important;
}
.count__section i {
  background: #000;
  border-radius: 5px;
  color: #fff;
  padding: 15px;
}

.user__profile i {
  background: #000;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
}

.list__sidebar li {
  list-style: none;
  margin-bottom: 12px;
  padding: 7px 20px !important;
}

.list__sidebar li:hover {
  background: linear-gradient(92deg, #5190f7 0%, #b681e7 50%, #bc32ae 100%)
}

.list__sidebar li i {
  color: #f5fbff;
  display: flex;
}

.list__sidebar li span {
  width: 170px;
  height: 30px;
  align-items: center;
  display: flex;
  text-decoration: none;
  border: none;

  font-family: "poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;

  color: #f5fbff;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.main-logo {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}
.list__sidebar a {
  text-decoration: none;
}

.main-logo p {
  color: #000;
  background: #fff;
  padding: 10px;
  font-weight: bold;
  border-radius: 10px;
}

.main-logo-open {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}

.main-logo-open p {
  color: #000;
  background: #fff;
  padding: 15px;
  font-size: 24px;
  font-weight: bold;
  border-radius: 10px;
}

.list__sidebar li p {
  margin-bottom: 0;
}

.charitydescription,
.charitydetail {
  width: 100px;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.del-charity {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.each__section table {
  padding: 0;
  border-collapse: separate;
  border-spacing: 0px 5px;
}

.each__section table tbody tr {
  /* border:#251B37 ; */
  /* background: rgb(248, 248, 248); */
  /* box-shadow: 5px 5px 15px -5px #44025f; */
  border-bottom: none;
  border: none;

  color: #000;
}

.each__section table tbody tr td {
  border: none;
  padding: 10px;
}

.each__section table thead {
  /* background: linear-gradient(#251B37 , #000000); */
  border-bottom: 1px solid rgb(154, 149, 149);

  /* background: rgb(248, 248, 248); */
  color: #000;
  /* border: none;
    box-shadow: 5px 5px 15px -5px #141515; */
  font-size: 16px;
  font-weight: bold;
}

.thead-dark tr th {
  padding: 10px 10px;
  font-family: "Poppins";
  white-space: nowrap;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background-color: #000d50 !important;
  vertical-align: top;
  color: #f5fbff;
}

.thead-dark tr th div.tablehead {
  display: flex;
  justify-content: space-between;
}

.tablehead {
  min-width: 81px;
}
.tablefilterdropdown {
  min-width: 81px;
}
.tablefilterdropdown .rmsc .dropdown-container {
  border-radius: 2px !important;
  padding: 1px !important;
  background-color: #5b78b7 !important;
  border-color: #5b78b7 !important;
}

.tablefilterdropdown .rmsc .dropdown-heading {
  height: 12px;
  padding: 0px 2px 0px 5px;
}

.tablefilterdropdown .rmsc .dropdown-heading .dropdown-heading-value .gray {
  font-size: 12px;
  font-weight: 500;
  color: #000d50 !important;
}


.tablefilterdropdown .rmsc .options {
  font-size: 12px;
  font-weight: 500;
  color: #000d50 !important;
}

.tablefilterdropdown .rmsc .dropdown-content {
  padding: 0px !important;
}

.tablefilterdropdown .rmsc .select-item {
  padding: 0px !important;
}

.tablefilterdropdown .rmsc .search input {
  padding: 0px !important;
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #000d50 !important;
}

.tablefilterdropdown .rmsc .search {
  font-size: 12px;
  font-weight: 400;
  color: #000d50 !important;
}

.tablefilterdropdown .rmsc .no-options { 
  padding: 0px !important;
}

.tablefilterdropdown .rmsc .search-clear-button {
  padding: 0px !important;
}
.thead-dark tr th svg.tablefilter {
  cursor: pointer;
  right: 0px;
  justify-content: end;
  padding-top: 1px;
}

.thead-dark tr th:first-child {
  border-top-left-radius: 12px;
}
.thead-dark tr th:last-child {
  border-top-right-radius: 12px;
}
.each__section table tbody tr td:first-child {
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
}
.each__section table tbody tr td:last-child {
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
}
.category-id {
  width: 550px !important;
}
.category-label {
  width: 550px !important;
}
.dimension-img {
  position: absolute;
  top: 15px;
  width: auto;
  margin-left: 13px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  background: #f5fbff;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
}
.dimension-img p {
  margin-bottom: 0;
}
.charity-tag-line {
  width: 200px;
}

.active {
  color: rgb(187, 182, 255) !important;
}

.each__section table tbody tr {
  background: #f5fbff;
}

.each__section table tbody tr td {
  /* Text sm/Normal */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  vertical-align: middle;
  color: #1d2939;
}

.view-images img {
  width: 25px;
  height: 25px;
}

.each__section table {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.thead-dark {
  background: #000d50;
  border-radius: 12px 12px 0px 0px;
  color: #ffff !important;
}

.actionbar {
  width: 20px;
}
.surveysingleviewimg {
  width: 100% !important;
  height: 200px !important;
  border: none !important;
  box-shadow: 5px 5px 15px -1px #dfe4e4 !important;
  border-radius: 30px !important;
  margin-bottom: 15px !important;
}

.surveysingleview {
  overflow-y: scroll;
  overflow-x: hidden;
}

.surveysingleview span {
  border: 2px solid rgb(255, 223, 223);
  padding: 2px;
  margin-right: 8px;
  background: #fff;
  color: #000;
}

.update__charity__btn {
  width: 100%;
  border: none;
  background: rgb(241, 241, 241);
  padding: 10px;
  box-shadow: 5px 5px 15px -5px #dfe4e4;
  border-radius: 20px;
}

.each__section tbody .second-img img {
  width: 50px;
  height: 50px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 8%),
    0px 4px 6px -2px rgba(16, 24, 40, 3%);
  border-radius: 50%;
  /* border: 2px solid #d5e0f7;
    box-shadow: 5px 5px 15px -5px #000101; */
}

.actions img {
  /* padding-right: 15px; */
  /* width: 40px !important;
  height: 25px !important; */
  /* margin-left: 5px; */
}

.add__charity {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.overflow-class {
  overflow: visible;
  overflow-y: auto;
  overflow-x: hidden;
}

.spinner {
  width: 56px;
  height: 56px;
  display: grid;
  border: 4.5px solid #0000;
  border-radius: 50%;
  border-color: #dbdcef #0000;
  animation: spinner-e04l1k 1s infinite linear;
}
.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2.2px;
  border: inherit;
  border-radius: 50%;
}
.spinner::before {
  border-color: #000d50 #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}
.spinner::after {
  margin: 8.9px;
}
@keyframes spinner-e04l1k {
  100% {
    transform: rotate(1turn);
  }
}

.redColor {
  color: red;
}
.overflow-class::-webkit-scrollbar {
  /* display: none; */
  scrollbar-width: auto;
  height: 5px;
}

/* Track */
.overflow-class::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

/* Handle */
.overflow-class::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 10px;
}

/* Handle on hover */
.overflow-class::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
  opacity: 0;
}

.sidebar-canvas {
  background-image: linear-gradient(#000000, #251b37);
  color: #fff !important;
  box-shadow: 5px 5px 15px -5px #dfe4e4;
}

.add__charity button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  background: #000d50;
  color: #fff;
  padding: 12px;
  border: none;
  display: flex;
  gap: 5px;
  border-radius: 10px;
}

.add__charity i {
  background: #fff;
  border-radius: 50px;
  color: #000d50;
  border: none;
  margin: 0px 11px 0px 0px;
}

.crud__btn {
  padding: 8px 15px 8px 15px;
  border: none;
  background: linear-gradient(#020202, #16171a);
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.donate__link {
  width: 100%;
  border: none;
  background: rgb(241, 241, 241);
  padding: 10px;
  box-shadow: 5px 5px 15px -5px #dfe4e4;
  border-radius: 20px;
}

.donate__link a {
  text-decoration: none;
  color: #000;
}

.charity__form {
  padding: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.add_charity {
  margin-top: 25px;
}

.add_charity button {
  border: 1px solid rgb(225, 225, 226);

  width: 100%;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  background-image: linear-gradient(#251b37, #0b0614);
  box-shadow: 5px 5px 15px -5px #dfe4e4;
}

.rmsc .dropdown-heading .dropdown-heading-value {
  color: #000;
}

.dropdown-content {
  color: #000;
}

/* Media query Section */

@media only screen and (max-width: 776px) {
  .image__col .img-box img {
    width: 100%;
  }

  .main-login {
    height: auto;
    overflow: inherit;
  }
}

@media only screen and (max-width: 600px) {
  .image__col .survey p {
    text-align: center;
    padding: 24px 0px 32px 0px;
    color: #fff;
  }
}

/* New Styling */
.main-logo {
  padding: 6px 51px;
  position: relative;
}

.main-logo img {
  width: 100%;
  padding-bottom: 10px;
}

.list__sidebar span {
  color: #f5fbff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-left: 10px;
}

.list__sidebar .active i {
  color: #f5fbff;
}
.filter-green {
}

.list__sidebar .active li {
  padding: 8px;
  background: #f5fbff;
}

.list__sidebar .active li i span,
.list__sidebar .active li .filter-green {
  color: #000d50 !important;
  filter: invert(92%) sepia(98%) saturate(1505%) hue-rotate(220deg)
    brightness(118%) contrast(119%);
}
.list__sidebar .active li .nav-color {
  color: #000d50;
  font-size: 18px;
}
.side__menu {
  height: calc(100vh - 150px);
  position: relative;
  overflow-y: scroll;
  padding-bottom: 70px;
}
.draft_btn button {
  width: 146px;
  height: 44px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
  margin-left: 25px;
  border: none;
  color: #f5fbff;
  background: #000d50;
  border-radius: 10px;
}

.main-logout {
  position: absolute;
  bottom: 0;
  background: #a35ee3;
  left: 0;
  right: 0;
}

.each__section h4 {
  color: #000d50;
  font-weight: 700;
  font-size: 24px;
  font-family: "Poppins";
}

.each__section .bg-light {
  background: #f5fbff !important;
  padding: 22px 50px;
}

.email-head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: right;

  color: #1d2939;
}

.form-sec-mac {
  padding: 33px;
}

.form-action label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #34335b;
}
.search-filter {
  background: #fff;
  border: 2px solid #aeadbd;
  padding: 10px 16px 10px 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.search-filter input {
  border: none;
  margin-left: 3px;
}
.no_data {
  width: 100%;
}
.loader-spinner {
  position: fixed;
  height: 100%;
  z-index: 9999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5fbff;
  opacity: 70%;
  color: #fff;
}
.search-filter input:focus {
  outline: none;
}
.form-action select {
  margin-left: 23px;
  /* width: 70px; */
  height: 45px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 15px 10px rgba(229, 237, 240, 33%);
  border-radius: 2px;
  border: 2px solid #aeadbd;
}

.icon-1 {
  width: 72.54px;
  height: 72.54px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 8%),
    0px 4px 6px -2px rgba(16, 24, 40, 3%);
  background: #f5edf0;

  border-radius: 45.9416px;
}

.icon-2 {
  width: 72.54px;
  height: 72.54px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 8%),
    0px 4px 6px -2px rgba(16, 24, 40, 3%);
  background: #f7f4e1;

  border-radius: 45.9416px;
}

.icon-3 {
  width: 72.54px;
  height: 72.54px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 8%),
    0px 4px 6px -2px rgba(16, 24, 40, 3%);
  display: flex;
  align-items: center;
  justify-content: center;

  background: #e5faf3;

  border-radius: 45.9416px;
}

.update-img img {
  width: 18px;
  height: 16px;
}
/* 
Single-charity section */
.main-single-char {
  margin: 40px 32px;
  background: #f5fbff;
  padding: 24px;
  border-radius: 16px;
}
.single-desc h3,
.desc-data h3,
.single-slider h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.single-desc p,
.desc-data p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #000000;
  padding-bottom: 15px;
}
.single-desc label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 21px;
}
.single-desc select {
  width: 320px;
  height: 48px;
  background: #fcfcfd;
  padding: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #1d2939;
  border: 1px solid #d9dee2;
  border-radius: 10px;
}
.single_button {
  display: flex;
  justify-content: end;
  gap: 5px;
}
.single_button span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  width: 146px;
  height: 44px;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #000d50;
  border: 1px solid #000d50;
  background: #f5fbff;
  border-radius: 10px;
}

.donate_btn button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 162px;
  height: 44px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  text-align: right;

  border: none;

  color: #f5fbff;

  background: #000d50;
  border-radius: 10px;
}

.donate_btn a,
.back_btn a,
.delete_btn a {
  text-decoration: none;
}
.back_btn button,
.back_btn a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  width: 146px;
  height: 44px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  text-align: right;

  color: #000d50;

  border: 1px solid #000d50;

  background: #f5fbff;
  border-radius: 10px;
}
.delete_btn button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 146px;
  height: 44px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  text-align: right;

  border: none;

  color: #ffffff;

  background: #d92d20;
  border-radius: 10px;
}
.cancel_btn button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  width: 146px;
  height: 44px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  text-align: right;

  color: #000d50;

  border: 1px solid #000d50;

  background: #ffffff;
  border-radius: 10px;
}
.single-slider .container {
  max-width: 1140px;
}
.slick-slide div {
  margin-right: 10px !important;
}
.main-add-charity {
  background: #f5fbff;
  border-radius: 16px;
  margin: 40px 32px;
  padding: 70px 27px;
}
.charity_form {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 500px));
  grid-gap: 32px;
  justify-content: space-around;
}
.fields_charity label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 21px;
}
.dropdown-container,
.select-basic-single {
  border-radius: 10px !important;
  padding: 4px !important;
  border: 1px solid #d9dee2 !important;
  background: #fcfcfd !important;
}
.select__control {
  border: none !important;
  box-shadow: none !important;
}
.fields_charity input {
  width: 100% !important;
  height: 48px;
  background: #fcfcfd;
  padding: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1d2939;
  border: 1px solid #d9dee2;
  border-radius: 10px;
}
.check-button {
  margin: 0px 32px 40px 32px;
  display: flex;
  justify-content: space-between;
}
.offers-button {
  margin: 0px 32px 40px 32px;
  display: flex;
  justify-content: end;
}
.check-box-charity p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}

.fields_charity select,
.fields_charity textarea {
  width: 100%;
  height: 48px;
  background: #fcfcfd;
  padding: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1d2939;
  border: 1px solid #d9dee2;
  border-radius: 10px;
}

.fields_charity textarea.clientNotes {
  height: 100px;
}

.fields_charity-1 label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 21px;
}
.fields_charity textarea {
  background: #fcfcfd;
  padding: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  resize: none;
  line-height: 24px;
  width: 100%;
  height: 247px;
  color: #1d2939;
  border: 1px solid #d9dee2;
  border-radius: 10px;
}
.img-field input[type="file"] {
  color: transparent !important;
  margin: 0px 0px 20px 15px;
}

.fields_charity-1 ::-webkit-file-upload-button {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  border: none;
  color: #1d2939;
  width: 100px;
  height: 20px;

  background: #dcdfea;
  border-radius: 4px;
}
.modal-header,
.modal-footer {
  border: none !important;
}
.modal-content {
  width: 438px !important;
  /* height: 274px !important; */
}
.category-tooltip {
  background: rgba(255, 255, 255, 1);
  padding: 6px 8px 6px 8px;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins";
  border-radius: 8px;
  cursor: pointer;
}
.tooltip-item {
  height: 100px;
  padding: 10px;
  overflow: scroll;
  overflow-x: hidden;
  /* background: #dbe8ff; */
  /* border-radius: 5px; */
}
.HeartIcon {
  width: 72.54px;
  height: 72.54px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%),
    0px 4px 6px -2px rgb(16 24 40 / 3%);
  background: #f5edf0;
  border-radius: 45.9416px;
}
.UserIcon {
  width: 72.54px;
  height: 72.54px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%),
    0px 4px 6px -2px rgb(16 24 40 / 3%);
  background: #f7f4e1;
  border-radius: 45.9416px;
}
.flex-1 {
  flex: 1;
}
.ContactIcon {
  width: 72.54px;
  height: 72.54px;
  box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%),
    0px 4px 6px -2px rgb(16 24 40 / 3%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5faf3;
  border-radius: 45.9416px;
}
.remove-cat-scroll {
  width: 100%;
  height: 230px;
  overflow: scroll;

  overflow-x: hidden;
}

.remove-cat-scroll::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  display: none;
}

/* Track */
.remove-cat-scroll::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

/* Handle */
.remove-cat-scroll::-webkit-scrollbar-thumb {
  background: #dd10151a;
  border-radius: 10px;
}

/* Handle on hover */
.remove-cat-scroll::-webkit-scrollbar-thumb:hover {
  background: #dd10151a;
}
.remove-cat {
  background: #f5fbff;
  display: flex;
  box-shadow: 5px 5px 15px -5px #dfe4e4;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;

  border: 1px solid rgb(220, 218, 218);
  margin-top: 10px;
  align-items: center;
}
.remove-cat p {
  margin-bottom: 0;
}
.remove-cat-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  background: #000d50;
  color: #fff;
  padding: 12px;
  border: none;
  width: 100%;
  border-radius: 10px;
}
.banner-image-scroll {
  width: 100%;
  display: flex;
  gap: 5px;
  padding-top: 10px;
  overflow: scroll;
  overflow-y: hidden;
}
.banner-image-scroll::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  display: none;
}

/* Track */
.banner-image-scroll::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

/* Handle */
.banner-image-scroll::-webkit-scrollbar-thumb {
  background: #dd10151a;
  border-radius: 10px;
}

/* Handle on hover */
.banner-image-scroll::-webkit-scrollbar-thumb:hover {
  background: #dd10151a;
}

.tooltip-box {
  width: 15px;
  height: 15px;
  background: rgb(202, 225, 255);
}
.inactive-survey-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  background: #000d50;
  color: #fff;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 10px;
}
.check-submissions {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  background: #000d50;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
}
.tooltip-item p {
  background: #f5fbff;

  margin-top: 5px;
  padding: 5px 10px 5px 10px;
  color: #000;
  box-shadow: 5px 5px 15px -5px #dfe4e4;
  border: 1px solid rgb(227, 226, 226);
  border-radius: 5px;
}
.react-datetime-picker {
  display: inline-flex;
  position: relative;
  /* border: none !important; */
  background: #fff;
  border: 1px solid #d9dee2;
  padding: 10px 35px;
  border-radius: 10px;
  width: 100%;
  background: #fcfcfd;
}
.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;

  flex-shrink: 0;
  border: none !important;
}

.react-datetime-picker__calendar .react-calendar {
  border-width: thin;
  background: #fff;
  color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  margin-top: 10px;
}
.react-datetime-picker__clear-button {
  display: none;
}
.react-datetime-picker__calendar-button {
  /* background: #ececec !important ;
  border-radius: 5px;
  border: 1px solid gray !important;
  padding: 4px !important; */
  background: url("../images/calenderIcon.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  /* margin-left: 50px; */
  padding: 12px !important;
}

.react-datetime-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  /* background-color: white; */
  background: #f5fbff !important;
  border-radius: 10px;
  display: none;
  margin-top: 10px;
  /* border: thin solid #a0a096; */
  border: 1px solid #d8d8d8 !important;
  /* color: #fff; */
}

.update__banner__home {
  width: 100px;
  height: 100px;
  border-radius: 12px;
  border: 1px solid rgba(87, 139, 182, 1);
}
.textarea-question {
  background-color: transparent;
  border: 2px solid #d9dee2;
  border-radius: 12px;
  padding: 8px;
}
.home-banner-input {
  border: none !important;
  display: none;
}
.question-answertype-input {
  border: none;
  display: none;
}
.browse label {
  color: rgba(5, 73, 112, 1);
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  border-bottom: 1px solid rgba(5, 73, 112, 1);
  cursor: pointer;
}
.modal-footer .add-question-btn {
  width: 48%;
  background: #000d50;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  color: #fff;
  padding: 10px 16px 10px 16px;
}
.modal-footer .cancel-btn {
  width: 48%;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #000d50;
  border-radius: 10px;
  color: #000d50;
  padding: 10px 16px 10px 16px;
}
.images__view__section {
  width: 100%;
  height: 200px;
  border: 1px solid #578bb6;
  padding: 10px;
  border-radius: 20px;
}
.view_question {
  position: relative;
}
.secondary__images__view {
  width: 40%;
  height: 20%;
}
.imageReference__placeholder {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20%;
}
.question__placeholder__img {
  position: absolute;
  top: 16px;
  right: 30px;
}
.options__section {
  position: relative;
  background: skyblue;
  border-radius: 14px;
}
.options__empty {
  position: absolute;
  top: 10px;
  left: 10px;
}
.option__add {
  position: absolute;
  top: 10px;
  right: 10px;
}
.delete__option__icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.video-upload-btn {
  width: 100%;
  background: #000d50;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  color: #fff;
  margin-top: 10px;
  padding: 10px 16px 10px 16px;
}

.add-options-btn {
  width: 100%;
  background: #000d50;
  border: none;
  border-radius: 10px;
  color: #fff;
  margin-top: 10px;
  padding: 10px 16px 10px 16px;
}
.main-survey-update {
  background: #f5fbff;
  border-radius: 16px;
  margin: 40px 32px;
  padding: 26px 27px;
}
.modal-backdrop {
  background: rgba(55, 55, 55, 0.33) !important;
  backdrop-filter: blur(4px) !important;
}

.add-charity-img img {
  width: 72px;
  height: 72px;
}
.img-field {
  display: flex;
  align-items: end;
}
.fields_charity-1 .img-field-1 input[type="file"] {
  margin: 0;
  color: transparent !important;
}
.fields_charity-1 .img-field-1 {
  margin-left: 16px;
  margin-top: 11px;
}
.img-field-1 ::-webkit-file-upload-button {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  border: none;
  color: #1d2939;
  width: 148px;
  height: 30px;

  background: #dcdfea;
  border-radius: 4px;
}
.charity_ques {
  margin-top: 21px !important;
}
.charity_ques h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #000000;
}
.charity_ques button {
  width: 137px;
  height: 40px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #101828;

  background: #d1e9ff;

  border: 1px dashed #000d50;
  border-radius: 10px;
}
.single-ques input {
  width: 100%;
  padding-left: 12px;
  height: 54px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #7b858f;

  background: #e0f2fe;

  border: 1px solid #d9dee2;
  border-radius: 10px;
}
.single-ques label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding-bottom: 5px;

  color: #434d56;
}
.main-survey-img {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 4px solid #000d50; */
  border-radius: 12px;
  padding: 15px 10px 15px 10px;
}
.surveydetail-bg {
  border: 4px solid #f4f1f1;
  border-radius: 20px;
  background: #e0f2fe;
  box-shadow: 5px 5px 15px -5px #dfe4e4;
  padding: 15px;
}
.check-box-charity .error__msg {
  color: red;
}
.main-survey-img .survey-img {
  border-radius: 12px;
}
.single-ques .del-img {
  position: absolute !important;
  top: 30px !important;
  right: 14px !important;
  width: 26px !important;
}
.single-ques .update-img {
  position: absolute !important;
  top: 30px !important;
  right: 32px !important;
  width: 26px !important;
  cursor: pointer;
}
.video-upload-input {
  border: none !important;
}
.add-question {
  display: flex;
  justify-content: space-between;
  background-color: #e0f2fe;
  align-items: center;
  font-size: 20px;
  margin-top: 10px;
  border-radius: 10px;
  padding: 8px;
}
.add-question .add-option {
  margin-bottom: 0;
  font-size: 16px !important;
  margin-left: 4px;
}
.add-question i {
  cursor: pointer;
}
.single-ques {
  position: relative;
}
.single-ques img {
  /* width: 50% !important; */
  width: 30px;
  height: 30px;
  margin-top: 12px !important;
}
.options h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  margin-top: 16px;

  color: #434d56;
}
.options p span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* Text Color on Light/Text Color Secondary - On Light - Grey 800 */

  color: #434d56;
}
.survey-img img {
  width: 200px;
  height: 200px;
}
.survey-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #000000;
}
.survey-desc p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  color: #000000;
}
.cursor {
  cursor: pointer;
}
.category-brick {
  margin-bottom: 0 !important;
  padding: 10px;
  border-radius: 5px;
  background: #dbe8ff;
  box-shadow: 5px 5px 15px -5px #dcd0ff;
  border: 1px solid #dbd5d5;
  margin-top: 5px !important;
}
.cross-banner-home {
  position: absolute;
  top: -10px;
  right: -4px;
  background: #d0d5dd;
  padding: 1px 6px;
  border-radius: 100%;
  cursor: pointer;
}
.add-category button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  background: #000d50;
  color: #fff;
  padding: 12px;
  display: flex;
  gap: 5px;
  border: none;
  border-radius: 10px;
}
.cat-add {
  font-family: "Poppins" !important;
  font-style: normal !important;
  width: 100% !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  margin-top: 10px !important;
  background: #000d50 !important;
  color: #fff !important;
  padding: 12px !important;
  border: none !important;
  border-radius: 10px !important;
}
.charityTagline {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins";
  text-overflow: ellipsis;
}
.update-img-field img {
  width: 125px;
  height: 120px;
  border-radius: 10px;
  background: #ddeffc;
  box-shadow: 5px 5px -15px 5px #d5ffff;
  padding: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.count-secondary-image {
  width: 170px;
  height: 120px;
  border-radius: 10px;
  background: #ddeffc;
  box-shadow: 5px 5px -15px 5px #d5ffff;
  padding: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.helping-survey {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px;
  /* identical to box height */

  color: #000000;
}
.survey-desc p span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #000000;
}
.ques-video {
  display: flex;
  align-items: center;
}
#outer {
  width: 250px;
  height: 150px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
}
.banner-update {
  width: 400px;
  height: 143px;

  background: #ffffff;

  border: 1px dashed #d9dee2;
  border-radius: 10px;
}
.banner-update {
  padding: 28px 31px;
}
.banner-update input {
  color: transparent;
}
.paginationBttns {
  width: 80%;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 1rem;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 20px;
}
.paginationBttns a {
  padding: 7px 13px;
  background-color: #eaecf0;
  color: rgb(16, 16, 61) !important;
  text-decoration: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px;
}
.modal-header {
  border-bottom: 2px solid rgba(102, 112, 133, 1) !important;
}
.modal-body p {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.modal-body input {
  background-color: transparent;
  border: 2px solid #d9dee2;
  border-radius: 12px;
  padding: 8px;
}
.modal-body select {
  background-color: transparent;
  border: 2px solid #d9dee2;
  border-radius: 12px;
  padding: 8px;
}
.activepage a {
  background-color: #000d50;
  color: #fff !important;
}

.btn:hover {
  background-color: #000d50;
  border-color: #000d50;
}

.blue-icons {
  filter: invert(92%) sepia(98%) saturate(1505%) hue-rotate(220deg)
    brightness(118%) contrast(119%);
}
.screen-images {
  margin-right: 12px;
}
.card-images-screen {
  margin-left: 40px;
  margin-top: 20px;
}

.screen-card-images {
  margin-left: 20px;
  margin-top: 20px;
}
.permission-save-btn {
  margin-top: 31px !important;
}
.general-settings-btn {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 150px;
}


.row.popout {
  border: 1px groove #000d50;
  padding-top: 15px;
  margin-top: 25px;
  padding-left: 10px;
  padding-right: 10px;
}

.row.popout .header {
  margin-top: -28px;
  margin-left: 0px;
}

.row.popout .header span {
  background-color: #000d50;
  border: #000d50 solid 1px;
  padding: 3px 150px 3px 10px;
  color: #fff;
  font-weight:  bolder;
  font-size: .85em;
}